<template>
    <v-container> 

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-forum</v-icon>
            Admin - Meeting Room
        </v-card-title>

        <!-- Looping List Tab -->
        <v-tabs
        v-model="tabs"
        show-arrows="mobile"
        >
            <v-tab v-for="item in tabsCarBooking" :key="item.tab" class="tab-icon">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.tabName }}
            </v-tab>
        </v-tabs>

        <!-- Container Tabs -->
        <v-tabs-items v-model="tabs">

            <!-- Tab Inbox -->
            <v-tab-item class="container-tab-modul">

                <inbox-datatable></inbox-datatable>

            </v-tab-item>

            <!-- Tab Calendar -->
            <v-tab-item class="container-tab-modul">
                
                <calendar-calendar></calendar-calendar>

            </v-tab-item>
            

        </v-tabs-items>

    </v-container>
</template>

<script>
import dtSysAdminDtMeetingRoom from '@/components/systemAdmin/meetingRoom/Datatable'
import dtSysAdminCalMeetingRoom from '@/components/systemAdmin/meetingRoom/Calendar'
export default {
    components: {
        'inbox-datatable': dtSysAdminDtMeetingRoom,
        'calendar-calendar': dtSysAdminCalMeetingRoom,
    },
    data: () => ({

        tabs: null,
        tabsCarBooking: [
            {tabName: "Inbox", icon: "mdi-inbox-multiple"},
            {tabName: "Calendar", icon: "mdi-calendar-multiselect"},
        ],


    }),
    methods: {

        // General
        load(){
            // console.log("Load");
        },


    },
    mounted(){
        this.load();
        // console.log(this.$store.getters.user);
        // console.log(this.$store);
    },
    computed:{}
}


</script>

<style lang="scss">
@import '~scss/main';

</style>
