<template>
    <v-container>

        <!-- Detail information -->
        <v-card v-if="onlyAdmin || onlyRoomManager">
            <v-card-text>
                <ul class="bullet-icon">
                    <li>
                    <v-icon class="primary--text">mdi-information</v-icon>
                    Booking cancellations cannot be canceled if the day of event has passed. For any issue regarding the room booking system, please contact HR.
                    </li>
                </ul>
            </v-card-text>
        </v-card>

        <!-- Details Table -->
        <v-card class="mt-5 darkTable table-card">

            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                class="mr-3 green"
                icon
                @click="exportExcel"
                >
                    <v-icon class="white--text">mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-text-field
                    v-model="searchDtMeetingRoom"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                    dense
                    class="shrink"
                ></v-text-field>
            </v-card-title>

            <v-data-table
            id="dtMeetingRoom"
            class="sizingTable"
            :items-per-page="10"
            :headers="headerMeetingRoom"
            :items="filteredData"
            :search="searchDtMeetingRoom"
            :loading="loadingDataTable"
            :loading-text="loadingText"
            :custom-sort="customSort"
            fixed-header
            >
                <template v-for="(header, i)  in headerMeetingRoom" v-slot:[`header.${header.value}`]="{  }"> 
                    <span @click.stop :key="i">
                        <v-text-field :key="i"
                        v-model="searchObj[header.value]"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        style="font-weight: 100;"
                        :disabled="isDisable(i)"
                        ></v-text-field>
                        {{ header.text }}
                    </span>
                </template>

                <template v-slot:[`item.bookingstatus`]="{ item }">
                    <v-chip
                    v-if="item.bookingstatus == 'BOOKED'"
                    color="primary"
                    class="text-bold white--text"
                    >
                    {{item.bookingstatus}}
                    </v-chip>

                    <v-chip
                    v-if="item.bookingstatus == 'CANCELLED'"
                    color="red"
                    class="text-bold white--text"
                    >
                    {{item.bookingstatus}}
                    </v-chip>
                </template>

                <template v-slot:[`item.assistanceStatus`]="{ item }">
                    
                    <!-- <v-icon
                    v-if="item.assistanceStatus == 'YES'"
                    class="success--text"
                    >
                        mdi-check-all
                    </v-icon> -->
                    <v-chip
                    v-if="item.assistanceStatus == 'YES'"
                    color="blue"
                    class="text-bold white--text"
                    >
                    REQUIRED
                    </v-chip>
                    
                    <!-- <span
                    v-if="item.assistanceStatus == 'NO'"
                    class="text-bold"
                    >
                        N/A
                    </span> -->
                    <v-chip
                    v-if="item.assistanceStatus == 'NO' || item.assistanceStatus == '' || item.assistanceStatus == null"
                    class="text-bold"
                    >
                    N/A
                    </v-chip>
                    
                    <!-- <span
                    v-if="item.assistanceStatus == '' || item.assistanceStatus == null"
                    class="text-bold"
                    >
                        N/A
                    </span> -->

                </template>

                <template v-slot:[`item.refreshmentsStatus`]="{ item }">
                    <v-chip
                    :color="controlBgColor(item.refreshmentsStatus)"
                    :class="controlColorFont(item.refreshmentsStatus)"
                    >
                    {{item.refreshmentsStatus}}
                    </v-chip>
                </template>

                <template v-slot:[`item.detailsAll`]="{ item }">
                    <v-icon
                    color="primary"
                    dense
                    v-html="item.detailsAll"
                    @click="openFileDoc(item)"
                    >
                    </v-icon>
                </template>

                

            </v-data-table>
        </v-card>

        <!-- Popup Details -->
        <template>
            <v-dialog
            v-model="dialogDisplayDetails"
            persistent
            scrollable
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Meeting Room Booking Form</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <tr
                        v-for="item in displayDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td>
                                <span
                                v-if="item.value != 'APPROVED' && item.value != 'REJECTED' && item.value != 'PENDING' && item.value != 'BOOKED' && item.value != 'CANCELLED'"
                                >
                                    <span v-if="item.key == 'Refreshment Requirement'" v-html="item.value" :class="item.key == 'Refreshment Requirement'? 'text-bold primary--text' : ''">
                                    </span>
                                    <span v-else-if="item.key == 'Assist Requirement'" v-html="item.value" :class="item.key == 'Assist Requirement'? 'text-bold primary--text' : ''">
                                    </span>
                                    <span v-else v-html="item.value">
                                    </span>
                                </span>

                                <v-chip
                                v-if="item.value == 'APPROVED' || item.value == 'REJECTED' || item.value == 'PENDING' || item.value == 'BOOKED' || item.value == 'CANCELLED'"
                                :color="controlBgColor(item.value)"
                                :class="controlColorFont(item.value)"
                                >
                                {{item.value}}
                                </v-chip>

                                <!-- <a v-if="item.key == 'Mileage Data Image' && item.path != null" :href="item.path" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="item.key == 'Mileage Data Image' && item.path == null" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a>  -->
                                
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-divider v-if="detailData != null && detailData.refreshmentsstatus == 'PENDING' && onlyAdmin || onlyRoomManager"></v-divider>

                <v-card-actions class="py-2">
                    <span
                    v-if="detailData != null && detailData.refreshmentsstatus == 'PENDING' && onlyAdmin || onlyRoomManager"
                    class="pl-7 body-2">
                        Refreshment Approvement
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                    v-if="detailData != null && detailData.refreshmentsstatus == 'PENDING'  && onlyAdmin || onlyRoomManager"
                    class="text-bold"
                    color="reject"
                    outlined
                    @click="rejectPending(detailData)"
                    >
                        Reject
                    </v-btn>
                    <v-btn
                    v-if="detailData != null && detailData.refreshmentsstatus == 'PENDING' && onlyAdmin || onlyRoomManager"
                    class="text-bold"
                    color="success"
                    outlined
                    @click="approvePending(detailData)"
                    >
                        Approve
                    </v-btn>
                </v-card-actions>

                <v-divider v-if="detailData != null && detailData.refreshmentsstatus == 'PENDING' && onlyAdmin || onlyRoomManager"></v-divider>

                <v-card-actions class="pa-5">
                    <span v-if="detailData != null && detailData.bookingstatus == 'BOOKED'">
                        <v-btn
                        class="text-bold"
                        color="red"
                        text
                        v-if="onlyAdmin || onlyRoomManager"
                        :disabled="xlehCancelBtn"
                        @click="openWarning(detailData)"
                        >
                            Cancel Booking
                        </v-btn>
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                    class="text-bold"
                    color="primary"
                    text
                    @click="dialogDisplayDetails = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Cancel Booking Room -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="warningDelete"
                persistent
                max-width="300"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-delete-empty</v-icon>
                        Cancel Booking?
                    </v-card-title>
                    <v-card-text class="blackk--text">Are you sure want to cancel this booking?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="warningDelete = false;"
                    >
                        NO
                    </v-btn>
                    <v-btn
                        class="text-bold red--text"
                        text
                        @click="warningDelete = false; cancelBooking(getDataSelectedDetails)"
                    >
                        YES
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Success Cancel -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogSuccess"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 success--text">
                        <v-icon class="icon-text success--text">mdi-check-circle</v-icon>
                        Success!
                    </v-card-title>
                    <v-card-text class="blackk--text">This room booking is succesfully delete in system.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogSuccess = false; dialogDisplayDetails = false; loadDataTable();"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Error -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogError"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Failed!
                    </v-card-title>
                    <v-card-text class="blackk--text">Something went wrong. Please try again.</v-card-text>
                    <br>
                    <v-card-text>
                        <small class="primary--text">
                            <span class="red--text text-bold">**</span> If the error still occurs, please contact HR.
                        </small> 
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogError = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>
        
    </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';
import Papa from "papaparse";

export default {
    
    data: () => ({

        dataForExport: [],

        loadingDataTable: true,
        loadingText: "", //Data is Loading... Please wait
        xlehCancelBtn: false,
        warningDelete: false,
        getDataSelectedDetails: null,
        dialogSuccess: false,
        dialogError: false,

        // Data Table
        searchDtMeetingRoom: '',
        headers: [],
        allData: [],
        searchObj: {},
        multiSearch: [],
        headerMeetingRoom: [
            {text: "Status", value: "bookingstatus"},
            {text: "Date Applied", value: "dateApplied"},
            {text: "Room Type", value: "roomType"},
            {text: "Event Title", value: "eventTitle"},
            {text: "Start Date Time", value: "startDateTime"},
            {text: "End Date Time", value: "endDateTime"},
            {text: "Tech Asst.", value: "assistanceStatus"},
            {text: "Refreshment", value: "refreshmentsStatus"},
            {text: "Detail", value: "detailsAll", sortable: false},
        ],
        meetingRoomBooking: [],

        // Display Details
        displayDetails: [
            // {key: "Status", attribute: "bookingstatus", value: "", icon: "mdi-forum"},    
            {key: "Status Booking", attribute: "bookingstatus", value: "bookingstatus", icon: "mdi-forum-outline"},    
            {key: "Event Title", attribute: "event", value: "", icon: "mdi-forum"},    
            {key: "Event Description", attribute: "description", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Date Applied", attribute: "dateapplied", value: "", icon: "mdi-calendar"},
            {key: "User Applied", attribute: "userApply", value: "", icon: "mdi-account"},    
            {key: "User Contact", attribute: "userContact", value: "", icon: "mdi-phone"},         
            {key: "Start Date Time", attribute: "start", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date Time", attribute: "end", value: "", icon: "mdi-calendar-end"},    
            {key: "Meeting Room", attribute: "name", value: "", icon: "mdi-google-classroom"},    
            {key: "Event Pax", attribute: "eventpax", value: "", icon: "mdi-account-group"},    
            {key: "Assist Requirement", attribute: "Assistanceremarks", value: "", icon: "mdi-desktop-classic"},       
            {key: "Refreshment Requirement", attribute: "refreshmentsremarks", value: "", icon: "mdi-food"},
            {key: "Refreshment Status", attribute: "refreshmentsstatus", value: "", icon: "mdi-check-decagram-outline"}, 
        ],
        dialogDisplayDetails: false,
        detailData: null,

        // Approve OR Reject
        listRoom: [
            {id: 1, label: "MNJ 01 Meeting Room", colorCheckbox: "primary", calendarColor: "red"},
            {id: 2, label: "MNJ 02 Discussion Room", colorCheckbox: "primary", calendarColor: "blue"},
            {id: 3, label: "SPW 01 Meeting Room", colorCheckbox: "primary", calendarColor: "green"},
            {id: 4, label: "SPW 02 Meeting Room", colorCheckbox: "primary", calendarColor: "purple"},
            {id: 5, label: "SCT 01 Meeting Room", colorCheckbox: "primary", calendarColor: "orange"},
            {id: 6, label: "SIS 01 Meeting Room", colorCheckbox: "primary", calendarColor: "grey"},
        ],
        jsonEditMeetingRoom: {
            idemp: "",
            idmeetingroomtype: "",
            event: "",
            description: "",
            startdatetime: "",
            enddatetime: "",
            eventpax: "",
            assistanceremarks: "",
            refreshmentsremarks: "",
            assistanceStatus: "",
            refreshmentsstatus: ""
        },

    }),
    methods: {

        // General
        load(){
            // console.log("Load");
            // console.log(this);
        },

        // Data Table Section
        loadDataTable() {

            this.loadingDataTable = true;
            this.loadingText = "Data is Loading... Please wait";

            this.allData = [];
            this.headers = [];

            this.headers = this.headerCarBooking

             axios.get(process.env.VUE_APP_API_URL+'meetingroom/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                let data = response.data
                // console.log(data);

                for(let i in data){
                    data[i]["dateApplied"] = this.convertDateTime(data[i]["dateapplied"]); 
                    data[i]["roomType"] =  data[i]["name"];
                    data[i]["eventTitle"] =  data[i]["event"];
                    data[i]["startDateTime"] = this.convertDateTime(data[i]["start"]); 
                    data[i]["endDateTime"] = this.convertDateTime(data[i]["end"]);
                    data[i]["assistanceStatus"] =  data[i]["assistancestatus"];
                    data[i]["refreshmentsStatus"] =  data[i]["refreshmentsstatus"];
                    data[i]["detailsAll"] = "mdi-file-document";
                    if(data[i]["bookingstatus"] == null || data[i]["bookingstatus"] == ""){
                        data[i]["bookingstatus"] = "BOOKED"
                    }
                }



                this.allData = data;
                this.loadingDataTable = false;
                this.loadingText = "";
                
            })
            .catch(error => {
                console.log(error.response);
                this.loadingDataTable = false;
                this.loadingText = "";
            })
        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            // items is every changes of susunan data tbody after sort..
            this.dataForExport = items;

            items.sort((a, b) => {
                if (index[0]=='dateApplied' || index[0]=='startDateTime' || index[0]=='endDateTime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        controlColorFont(status){
            if (status == "APPROVED") {
                return "white--text text-bold" 
            }
            else if (status == "REJECTED") {
                return "white--text text-bold" 
            }
            else if (status == "PENDING") {
                return "white--text text-bold" 
            }
            else if (status == "BOOKED") {
                return "white--text text-bold" 
            }
            else if (status == "CANCELLED") {
                return "white--text text-bold" 
            }
            else if(status == "N/A"){
                return "blackk--text text-bold" 
            }
        },
        
        controlBgColor(status){
            if (status == "APPROVED") {
                return "success" 
            }
            else if (status == "REJECTED") {
                return "reject" 
            }
            else if (status == "PENDING") {
                return "pending" 
            }
            else if (status == "BOOKED") {
                return "primary" 
            }
            else if (status == "CANCELLED") {
                return "red" 
            }
            else if(status == "N/A"){
                // return "white" 
            }
        },

        isDisable(index) {
            // console.log(index);
            if (index == 5 || index == 7) {
                return true;
            }
            else{
                return false;
            }
        },

        openFileDoc(data){
            // console.log(data);

            if (new Date() - new Date(data.end) > 0) {
                this.xlehCancelBtn = true;
            }
            else if(this.onlyInfra){
                this.xlehCancelBtn = true;
            }
            else{
                this.xlehCancelBtn = false;
            }

            // this.xlehCancelBtn = true;

            let keyData = Object.keys(data)
            for(let i in this.displayDetails){

                for(let x in keyData){
                    if (this.displayDetails[i].attribute == keyData[x]) {
                        
                        if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                            this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                        }
                        else if (keyData[x] == "description") {
                            if (data.description == "" || data.description == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "userContact") {
                            if (data.userContact == "" || data.userContact == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "assistancestatus") {
                            if (data.assistancestatus == "YES") {
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                            else if (data.assistancestatus == "NO") {
                                this.displayDetails[i].value = "N/A";
                            }
                        }
                        else if (keyData[x] == "Assistanceremarks") {
                            if (data.Assistanceremarks == "" || data.Assistanceremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "refreshmentsremarks") {
                            if (data.refreshmentsremarks == ""  || data.refreshmentsremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "idmeetingroom") {
                            for(let y in this.listRoom){
                                if (data.idmeetingroom == this.listRoom[y].id) {
                                    this.displayDetails[i].value = this.listRoom[y].label;
                                }
                            }
                        }
                        else{
                            this.displayDetails[i].value = data[keyData[x]];
                        }
                    }
                }

            }

            // Untuk collect data selection
            this.detailData = data;
            // console.log(this.detailData);

            this.dialogDisplayDetails = true;
        },

        openWarning(data){
            this.warningDelete = true;
            this.getDataSelectedDetails = data;
        },

        cancelBooking(data){

            // console.log(data);
            // console.log(process.env.VUE_APP_API_URL+"meetingroom/cancelbook?idmeetingroomtype="+data.idmeetingroom+"&startdate="+data.start+"&enddate="+data.end);

            let payloadEdit = {
                idemp: data.idemp,
                idmeetingroomtype: data.idmeetingroom,
                event: data.event,
                description: data.description,
                startdatetime: data.start,
                enddatetime: data.end,
                eventpax: data.eventpax,
                assistanceremarks: data.Assistanceremarks,
                refreshmentsremarks: data.refreshmentsremarks,
                assistanceStatus: "",
                refreshmentsstatus: "N/A",
                bookingstatus: "CANCELLED",
            }

            let jsonString = JSON.stringify(payloadEdit);
            console.log(jsonString);

            axios.put(process.env.VUE_APP_API_URL+"meetingroom/editbook",jsonString,
            {
                headers: {
                    "Authorization": 'Bearer ' + this.$store.getters.user.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                bus.$emit('changeIt', 'loadNotify');
                this.dialogSuccess = true;
                
            })
            .catch(e => {
                console.log(e.response);
                this.dialogSuccess = false;
                this.dialogError = true;
            })

            // Uncomment code below kalau nk pakai API delete list meeting room ====
            // axios.get(process.env.VUE_APP_API_URL+"meetingroom/cancelbook?idmeetingroomtype="+data.idmeetingroom+"&startdate="+data.start+"&enddate="+data.end,
            // {
            //     headers: {
            //         "Authorization": 'Bearer ' + this.$store.getters.user.token,
            //     }
            // })
            // .then(response => { //eslint-disable-line
            //     // console.log(response);
            //     this.dialogSuccess = true;
            //     bus.$emit('changeIt', 'loadNotify');
            // })
            // .catch(e => {
            //     console.log(e.response);
            //     this.dialogSuccess = false;
            //     this.dialogError = true;
            // })

        },

        rejectPending(data){
            for(let i in this.listRoom){
                if (this.listRoom[i].label == data.roomType) {
                    this.jsonEditMeetingRoom.idmeetingroomtype =  this.listRoom[i].id;
                }
            }
            this.jsonEditMeetingRoom.idemp = data.idemp;
            this.jsonEditMeetingRoom.event = data.event;
            this.jsonEditMeetingRoom.description = data.description;
            this.jsonEditMeetingRoom.startdatetime = data.start;
            this.jsonEditMeetingRoom.enddatetime = data.end;
            this.jsonEditMeetingRoom.eventpax = data.eventpax;
            this.jsonEditMeetingRoom.assistanceremarks = data.Assistanceremarks;
            this.jsonEditMeetingRoom.refreshmentsremarks = data.refreshmentsremarks;
            this.jsonEditMeetingRoom.assistanceStatus = data.assistanceStatus;
            this.jsonEditMeetingRoom.refreshmentsstatus = "REJECTED";

            let updateJson = JSON.stringify(this.jsonEditMeetingRoom);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL+"meetingroom/editbook",
            updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogDisplayDetails = false;
                this.loadDataTable();
                bus.$emit('changeIt', 'loadNotify');
            })
            .catch(e => {
                console.log(e.response);
            })

        },

        approvePending(data){
            for(let i in this.listRoom){
                if (this.listRoom[i].label == data.roomType) {
                    this.jsonEditMeetingRoom.idmeetingroomtype =  this.listRoom[i].id;
                }
            }
            this.jsonEditMeetingRoom.idemp = data.idemp;
            this.jsonEditMeetingRoom.event = data.event;
            this.jsonEditMeetingRoom.description = data.description;
            this.jsonEditMeetingRoom.startdatetime = data.start;
            this.jsonEditMeetingRoom.enddatetime = data.end;
            this.jsonEditMeetingRoom.eventpax = data.eventpax;
            this.jsonEditMeetingRoom.assistanceremarks = data.Assistanceremarks;
            this.jsonEditMeetingRoom.refreshmentsremarks = data.refreshmentsremarks;
            this.jsonEditMeetingRoom.assistanceStatus = data.assistanceStatus;
            this.jsonEditMeetingRoom.refreshmentsstatus = "APPROVED";

            let updateJson = JSON.stringify(this.jsonEditMeetingRoom);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL+"meetingroom/editbook",
            updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogDisplayDetails = false;
                this.loadDataTable();
                bus.$emit('changeIt', 'loadNotify');
            })
            .catch(e => {
                console.log(e.response);
            })
        },

        exportExcel(){

            let keys = [
                "dateApplied",
                "roomType",
                "eventTitle",
                "startDateTime",
                "endDateTime",
                "endDateTime",
                "assistanceStatus",
                "Assistanceremarks",
                "refreshmentsStatus",
                "refreshmentsremarks",
                "userApply",
                "userContact",
            ],
            resultAfterFilter = this.dataForExport.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

            let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");

            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'List of Booking Meeting Room.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    },
    mounted(){
        this.load();
        this.loadDataTable();
    },
    computed: {
        filteredData(){
            if (this.searchObj) {
                return this.allData.filter(item => {
                    return Object.entries(this.searchObj).every(([key, value]) => {
                        return (item[key] ||'').toLowerCase().includes(value.toLowerCase())
                    })
                })
            }
            else{
                return this.allData
            } 
        },
    }
}

</script>

<style lang="scss">
@import '~scss/main';

// Custom default
// .sizingTable > .v-data-table__wrapper{
//     max-height: calc(100vh - 370px);
// } Sebab laptop Kak Mi hancur viewer (over max height)

// .table-card{
//     height: calc(100vh - 240px);
// }

</style>