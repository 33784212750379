<template>
    <v-container>
        
        <!-- Vue Calendar -->
        <v-row class="fill-height">
            <v-col>
            <v-sheet class="calendarSizing" height="65">
                <v-toolbar
                flat
                >
                <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
                >
                    Today
                </v-btn>
                <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
                >
                    <v-icon small>
                    mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
                >
                    <v-icon small>
                    mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                bottom
                right
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>
                        mdi-menu-down
                        </v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                    <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
                <v-calendar
                :available-dates='{ start: new Date(), end: null }'
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="loadDataCalendar"
                ></v-calendar>
                <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
                max-width="480px"
                >
                    <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                    >
                        <v-toolbar
                        :color="selectedEvent.color"
                        dark
                        >
                        <v-btn icon @click="viewData(selectedEvent)">
                            <v-icon>mdi-text-box-outline</v-icon>
                        </v-btn>
                        <v-toolbar-title dense collapse v-html="selectedEvent.name"></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="selectedOpen = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-toolbar>
                        <v-card-text >
                            <v-simple-table dense>
                                <template v-slot:default>
                                <tbody>
                                    <tr v-if="$vuetify.breakpoint.lgAndUp">
                                        <td colspan="2">

                                            <span v-for="(item, i) in listAllRooms" :key="i">
                                                <!-- <span v-if="item.id === selectedEvent.idmeetingroom"> -->
                                                    <v-img
                                                    v-if="item.id === selectedEvent.idmeetingroom"
                                                    class="my-3"
                                                    height="150"
                                                    width="350"
                                                    :src="item.src"
                                                    >
                                                    </v-img>
                                                <!-- </span> -->
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-calendar-clock</v-icon>
                                            Start Date
                                            <!-- <v-icon small>mdi-transfer-right</v-icon> -->
                                        </td>
                                        <td>
                                            <span v-html="convertDateTime(selectedEvent.start)"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-calendar-clock</v-icon>
                                            End Date
                                            
                                        </td>
                                        <td>
                                            <span v-html="convertDateTime(selectedEvent.end)"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-account</v-icon>
                                            User
                                        </td>
                                        <td>
                                            <span v-html="selectedEvent.userApply"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-cellphone-sound</v-icon>
                                            Contact
                                        </td>
                                        <td>
                                            <span v-if="selectedEvent.userContact != null" v-html="selectedEvent.userContact"></span>
                                            <span v-if="selectedEvent.userContact == null">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-card-text-outline</v-icon>
                                            Event
                                        </td>
                                        <td>
                                            <span v-html="selectedEvent.event"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-projector</v-icon>
                                            Assistance Status
                                        </td>
                                        <td>
                                            <v-chip
                                            v-if="selectedEvent.assistancestatus == 'YES' "
                                            color="primary"
                                            class="white--text"
                                            small
                                            >
                                                <span class="text-bold">REQUIRED</span>
                                            </v-chip>
                                            <v-chip
                                            v-if="selectedEvent.assistancestatus == 'NO' || selectedEvent.assistancestatus == null"
                                            color="grey"
                                            class="white--text"
                                            small
                                            >
                                                <span class="text-bold">N/A</span>
                                            </v-chip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-coffee</v-icon>
                                            Refreshment Status
                                        </td>
                                        <td>
                                            <v-chip
                                            v-if="selectedEvent.refreshmentsstatus == 'APPROVED' "
                                            color="success"
                                            class="white--text"
                                            small
                                            >
                                                <span v-html="selectedEvent.refreshmentsstatus" class="text-bold"></span>
                                            </v-chip>
                                            <v-chip
                                            v-if="selectedEvent.refreshmentsstatus == 'PENDING' "
                                            color="pending"
                                            class="white--text"
                                            small
                                            >
                                                <span v-html="selectedEvent.refreshmentsstatus" class="text-bold"></span>
                                            </v-chip>
                                            <v-chip
                                            v-if="selectedEvent.refreshmentsstatus == 'REJECTED' "
                                            color="red"
                                            class="white--text"
                                            small
                                            >
                                                <span v-html="selectedEvent.refreshmentsstatus" class="text-bold"></span>
                                            </v-chip>
                                            <v-chip
                                            v-if="selectedEvent.refreshmentsstatus == 'N/A' "
                                            color="grey"
                                            class="white--text"
                                            small
                                            >
                                                <span v-html="selectedEvent.refreshmentsstatus" class="text-bold"></span>
                                            </v-chip>

                 
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        <v-card-actions>
                        <!-- <v-btn
                        class="text-bold"
                        text
                        color="primary"
                        @click="selectedOpen = false"
                        >
                            Close
                        </v-btn> -->
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
            </v-col>
        </v-row>
        
        <!-- Label Coloring Car -->
        <div
        style="display:flex;"
        >
            <v-container>
                <v-row>
                    <span v-for="item in listMeetingRoom" :key="item.roomColor" style="margin-right:30px;">
                        <v-col>
                            <small>
                                <v-icon
                                class="icon-text"
                                v-bind:style="item.color">
                                    {{item.icon}}
                                </v-icon>
                                <span>{{item.room}}</span>
                            </small>
                        </v-col>
                    </span>
                </v-row>
            </v-container>
        </div>


        <template>
            <v-dialog
            v-model="singleOptionDayEvent"
            width="200"
            scrollable
            >
            <v-card>
                <v-card-title class="justify-center title-bar-modul">
                    <h4>Day Event</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col
                            align="center"
                        >
                            <v-btn
                                color="primary"
                                outlined
                                @click="zoomDaySelected"
                            >
                                Day Event
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-dialog>
        </template>


        <!-- Popup View Details -->
        <template>
            <v-dialog
            v-model="dialogViewDataMeetingRoom"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Details Meeting Room</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>

                        <v-row>
                            <v-col>
                                <v-simple-table class="table-details-info">
                                    <template v-slot:default>
                                    <tbody>
                                        <tr
                                        v-for="item in displayDetails"
                                        :key="item.name"
                                    
                                        >
                                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                                {{ item.key }}
                                            </td>
                                            
                                            
                                            <td>

                                                <span v-if="item.value != 'PENDING' && item.value != 'APPROVED' && item.value != 'REJECTED' " >
                                                    <span v-if="item.key == 'Refreshment Requirement'" v-html="item.value" :class="item.key == 'Refreshment Requirement'? 'text-bold primary--text' : ''">
                                                    </span>
                                                    <span v-else-if="item.key == 'Assist Requirement'" v-html="item.value" :class="item.key == 'Assist Requirement'? 'text-bold primary--text' : ''">
                                                    </span>
                                                    <span v-else v-html="item.value">
                                                    </span>
                                                </span>

                                                <v-chip
                                                v-if="item.value == 'APPROVED' "
                                                color="success"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                
                                                <v-chip
                                                v-if="item.value == 'PENDING' "
                                                color="pending"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>

                                                <v-chip
                                                v-if="item.value == 'REJECTED' "
                                                color="red"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                
                                                <!-- <span v-if="item.value != 'PENDING'">
                                                    <a v-if="item.value != null" :href="item.value" target="_blank">
                                                        <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                                        <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                                    </a>
                                                    <a v-if="item.value == null" style="cursor:default;">
                                                        <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                                        <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                                    </a> 
                                                </span> -->
                                                    
                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        
                    </v-container>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    class="text-bold"
                    color="primary"
                    text
                    @click="dialogViewDataMeetingRoom = false;"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </template>

        <!-- Warning Blank Field Form -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogWarningBlankInput"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text" color="red">mdi-alert-circle</v-icon>
                        Warning!
                    </v-card-title>
                    <v-card-text class="blackk--text">Please fill out all of required field.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="dialogWarningBlankInput = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    
    data: () => ({

        // Calendar
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        storeDataDisplayCalendar: [],
        dialogAddDateMeetingRoom: false,
        listMeetingRoom: [
            {room: "MNJ 01 Meeting Room", color: "color: red;", icon: "mdi-chair-rolling"},
            {room: "MNJ 02 Discussion Room", color: "color: #2196f3;", icon: "mdi-chair-rolling"},
            {room: "SPW 01 Meeting Room", color: "color: #4caf50;", icon: "mdi-chair-rolling"},
            {room: "SPW 02 Meeting Room", color: "color: purple;", icon: "mdi-chair-rolling"},
            {room: "SCT 01 Meeting Room", color: "color: orange;", icon: "mdi-chair-rolling"},
            {room: "SIS 01 Meeting Room", color: "color: grey darken-1;", icon: "mdi-chair-rolling"},
        ],

        // Store for combination date and time
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        startTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        endTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),


        // Set rule field is required
        rules: {
          eventName: [val => (val || '').length > 0 || 'This field is required'],
          startTime: [val => (val || '').length > 0 || 'Start time is required'],
          endTime: [val => (val || '').length > 0 || 'End Time is required'],
        },


        // Engines/Dialog/Listing related to data form
        radios1: null,    
        menuStartDt: false,
        modalStartTime: false,
        datePickerStartToday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuEndtDt: false,
        modalEndTime: false,
        radios2: null,
        optionDayEvent: false,
        singleOptionDayEvent: false,
        daySelected: null,
        imageMil: null,
        visibleRoom: false,
        listAllRooms: [
            {
                id: 1,
                src: require('@/assets/img/meetingRooms/MNJ1.jpg'),
                name: "MNJ 01 Meeting Room"
            },
            {
                id: 2,
                src: require('@/assets/img/meetingRooms/MNJD.jpg'),
                name: "MNJ 02 Discussion Room"
            },
            {
                id: 3,
                src: require('@/assets/img/meetingRooms/SPW1.jpg'),
                name: "SPW 01 Meeting Room"
            },
            {
                id: 4,
                src: require('@/assets/img/meetingRooms/SPW2.jpg'),
                name: "SPW 02 Meeting Room"
            },
            {
                id: 5,
                src: require('@/assets/img/meetingRooms/SPW3.jpg'),
                name: "SCT 01 Meeting Room"
            },
            {
                id: 6,
                src: require('@/assets/img/meetingRooms/SIS1.jpg'),
                name: "SIS 01 Meeting Room"
            },
        ],
        
        // Dialog
        dialogWarningBlankInput: false,
        dialogViewDataMeetingRoom: false,

        // View Data
        displayDetails: [
            {key: "Event Title", attribute: "event", value: "", icon: "mdi-forum"},    
            {key: "Event Description", attribute: "description", value: "", icon: "mdi-forum-outline"},    
            {key: "Date Applied", attribute: "dateapplied", value: "", icon: "mdi-calendar"},
            {key: "User Applied", attribute: "userApply", value: "", icon: "mdi-account"},    
            {key: "User Contact", attribute: "userContact", value: "", icon: "mdi-phone"},         
            {key: "Start Date Time", attribute: "start", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date Time", attribute: "end", value: "", icon: "mdi-calendar-end"},    
            {key: "Meeting Room", attribute: "name", value: "", icon: "mdi-google-classroom"},    
            {key: "Event Pax", attribute: "eventpax", value: "", icon: "mdi-account-group"},    
            {key: "Assist Requirement", attribute: "Assistanceremarks", value: "", icon: "mdi-desktop-classic"},       
            {key: "Refreshment Requirement", attribute: "refreshmentsremarks", value: "", icon: "mdi-food"},
            {key: "Refreshment Status", attribute: "refreshmentsstatus", value: "", icon: "mdi-check-decagram-outline"},      
        ],

    }),
    methods: {

        // General
        load(){
            // console.log("Load");
        },


        // Calendar Vuetify Section
        viewDay ({ date }) {

            // console.log(Date.parse(date) + " is " + new Date(date));
            // console.log(Date.parse(new Date()) + " is " + new Date());

            // Set Value input/field in Form Room Book
            this.daySelected = date;
            this.startDate = date;
            this.endDate = date;
            this.startTime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5);
            this.endTime = this.startTime;

            

            // Uncomment below code for zoom event day
            this.focus = date;
            this.type = 'day';

        },
        zoomDaySelected(){
            this.focus = this.daySelected;
            this.type = 'day'
            this.optionDayEvent = false
            this.singleOptionDayEvent = false
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {

            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target

                let myInterval = setInterval(() => {
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                    clearInterval(myInterval);
                }, 500);
                
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        loadDataCalendar () {

            axios.get(process.env.VUE_APP_API_URL + 'meetingroom/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let storeDat = [];
                let data = response.data
                // console.log(data);
                data.forEach(function(res){
                    res["color"] = res["color"].toLowerCase();
                    if(res['bookingstatus'] != "CANCELLED"){
                        storeDat.push(res);
                    }
                    
                });

                this.events = storeDat;
                // console.log(this.events);
                
            })
            .catch(error => {
                console.log(error.response);
            })

        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        handleFileUpload(e){
            this.objectPostDataForm.imageMileage = e;
        },

        viewData(data){

            // console.log(data);

            // Json just for display
            // let keyData = Object.keys(data)
            // for(let i in this.displayDetails){
            //     for(let x in keyData){
            //         if (this.displayDetails[i].attribute == keyData[x]) {
                        
            //             if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
            //                 this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
            //             }
            //             else{
            //                 this.displayDetails[i].value = data[keyData[x]];
            //             }
            //         }
            //     }
            // }

            let keyData = Object.keys(data)
            for(let i in this.displayDetails){

                for(let x in keyData){
                    if (this.displayDetails[i].attribute == keyData[x]) {
                        
                        if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                            this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                        }
                        else if (keyData[x] == "description") {
                            if (data.description == "" || data.description == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "userContact") {
                            if (data.userContact == "" || data.userContact == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "assistancestatus") {
                            if (data.assistancestatus == "YES") {
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                            else if (data.assistancestatus == "NO") {
                                this.displayDetails[i].value = "N/A";
                            }
                        }
                        else if (keyData[x] == "Assistanceremarks") {
                            if (data.Assistanceremarks == "" || data.Assistanceremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "refreshmentsremarks") {
                            if (data.refreshmentsremarks == "" || data.refreshmentsremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "idmeetingroom") {
                            for(let y in this.listRoom){
                                if (data.idmeetingroom == this.listRoom[y].id) {
                                    this.displayDetails[i].value = this.listRoom[y].label;
                                }
                            }
                        }
                        else{
                            this.displayDetails[i].value = data[keyData[x]];
                        }
                    }
                }

            }
            
            this.dialogViewDataMeetingRoom = true;
        }

    },
    mounted(){
        this.load();
        // this.objectPostDataForm.userApply = this.$store.getters.user.name;
        // this.objectPostDataForm.userContact = this.$store.getters.user.phone;
    }
}

</script>

<style lang="scss">
@import '~scss/main';



</style>